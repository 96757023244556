<template>
  <div style="width: 100%">
    <!-- <v-text-field
      style="width: 100%"
      v-model="cardNumber"
      label="Card Number"
      mask="credit-card"
      outlined
      block
    ></v-text-field>
    <v-text-field
      style="width: 100%"
      v-model="cardNumber"
      label="Expiry Date"
      mask="credit-card"
      outlined
      block
    ></v-text-field>
    <v-text-field
      style="width: 100%"
      v-model="cardNumber"
      label="CVV"
      mask="credit-card"
      outlined
      block
    ></v-text-field>
    <v-text-field
      style="width: 100%"
      v-model="cardNumber"
      label="Card Holder"
      mask="credit-card"
      outlined
      block
    ></v-text-field> -->
    <stripe-element-card
      style="width: 100%"
      ref="elementRef"
      :hide-postal-code="true"
      :pk="stripeKey"
    />
    <v-btn
      class="mt-3"
      block
      color="primary"
      elevation="5"
      :loading="loading"
      large
      @click="addCard"
    >
      Submit
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import secrets from "../helpers/secrets.js";
import axios from "@axios";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  props: {
    initialSetup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StripeElementCard,
  },
  data() {
    return {
      loading: false,
      stripeKey:
        process.env.NODE_ENV === "production"
          ? secrets.stripePublicKey
          : secrets.stripePublicKeyTest,
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
    stripeCustomerId() {
      return this.currentOrganization.stripeCustomerId;
    },
  },
  methods: {
    async addCard() {
      try {
        this.loading = true;
        if (!this.stripeCustomerId) {
          await this.createStripeCustomer();
        }
        const { stripe } = this.$refs.elementRef;
        const card = this.$refs.elementRef.element;
        const billingDetails = {
          name: this.currentOrganization.organization,
          email: this.currentOrganization.email,
        };
        const res = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: billingDetails,
        });
        await this.attachMethodToCustomer(res.paymentMethod.id);
        if (this.initialSetup) {
          this.completePaymentSetup();
        }
        this.$emit("cardAdded");
        this.$toast.success("Card added");
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message || "Failed to add card");
      }
      this.loading = false;
    },
    async attachMethodToCustomer(paymentMethodId) {
      const token = await firebase.auth().currentUser.getIdToken();

      console.log(paymentMethodId);
      console.log(this.currentOrganization.id);

      await axios.post(
        "/organisation/attach-payment-methods",
        {
          organizationId: this.currentOrganization.id,
          paymentMethodId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    async createStripeCustomer() {
      const token = await firebase.auth().currentUser.getIdToken();
      console.log("OrgId");
      console.log(this.currentOrganization.id);
      await axios.post(
        "/organisation/create-customer",
        {
          organizationId: this.currentOrganization.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await this.$store.dispatch("auth/fetchCurrentOrganization");
    },

    async completePaymentSetup() {
      await firebase
        .firestore()
        .collection("Organizations")
        .doc(this.currentOrganization.id)
        .update({
          payment_setup: true,
        });
    },
  },
};
</script>
