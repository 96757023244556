import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://us-central1-reallyme-66efd.cloudfunctions.net/api"
      : "https://us-central1-reallyme-66efd.cloudfunctions.net/api_test",
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
